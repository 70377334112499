import L from 'leaflet'
import ReactDOMServer from 'react-dom/server'
import { FacilityCode } from '../../../domain/entities/IFacility'
import PinIcon from './PinIcon'

export const korianBlue = '#003E5C'

export const codeToColour = new Map<FacilityCode, string>([
  [FacilityCode.F001T, '#D5572A'], // Colocation Ages & Vies
  [FacilityCode.F002T, '#003E5C'], // Clinique de santé mentale
  [FacilityCode.F003T, '#00ABBE'], // Clinique de Soins médicaux et de réadaptation (SMR)
  [FacilityCode.F004T, '#EF7D00'], // Maison de retraite (EHPAD)
  [FacilityCode.F005T, '#0069DA'], // Service à domicile Petits-fils

  // [FacilityCode.F001T, '#EF7D00'], // Maison de retraite médicalisée (EHPAD)
  // [FacilityCode.F002T, '#FFD205'], // Résidence Seniors
  // [FacilityCode.F003T, '#DDDDDD'], // Maison d'accueil spécialisée
  // [FacilityCode.F004T, '#003E5C'], // Clinique de santé mentale
  // [FacilityCode.F005T, '#037EAA'], // Hôpital de jour
  // [FacilityCode.F006T, '#00ABBE'], // Clinique SMR
  // [FacilityCode.F007T, '#A9CC4B'], // Hospitalisation à domicile
  // [FacilityCode.F008T, '#BAD66F'], // Services de Soins Infirmiers à Domicile (SSIAD)
  // [FacilityCode.F009T, '#D5572A'], // Colocations Âges et vies
  // [FacilityCode.F010T, '#0069DA']  // Service à domicile Petit-fils
])

export function createSVG(code: FacilityCode) {
  return <PinIcon color={codeToColour.get(code)} />
}

export function createIcon(code: FacilityCode) {
  return L.divIcon({
    className: 'custom icon',
    html: ReactDOMServer.renderToString(<PinIcon color={codeToColour.get(code)} scale={0.7} />)
  })
}
